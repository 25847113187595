/* Font Faces */
$neat-grid: (
  columns: 12,
  gutter: 0px,
);

$phi: 1.6180339887498948482;
$goldenRatio1: 1 / $phi * 100%;
$goldenRatio2: 100% - (100% / $phi);
$breakpoint-font: 850px;
$breakpoint-font2: 540px;

@font-face {
  font-family: "ostrich_sansmedium";
  src: url("/etc/fonts/ostrichsans-medium-webfont.woff2") format("woff2"),
    url("/etc/fonts/ostrichsans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font Faces End */

/* Vars */

/* End Vars */

html,
body,
.container {
  height: 100%;
  width: 100%;
  font-family: ostrich_sansmedium, serif;
}

.container {
  @include grid-container;
  //@include grid-visual;
}

.three {
  @include grid-column(3);
  @media (max-width: $breakpoint-font2) {
    @include grid-column(4);
  }
}
.six {
  @include grid-column(6);
  @media (max-width: $breakpoint-font2) {
    @include grid-column(4);
  }
}
.four {
  @include grid-column(4);
}
.twelve {
  @include grid-column(12);
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.visually-hidden {
  opacity: 0;
}

.goldenRatio1 {
  height: $goldenRatio1;
  @media (max-width: $breakpoint-font) {
    height: auto;
  }
}

.goldenRatio2 {
  height: $goldenRatio2;
  @media (max-width: $breakpoint-font) {
    height: auto;
  }
}

.headingName {
  text-align: center;
  font-size: 9.5em;
  font-weight: bold;
  height: $goldenRatio1;
  line-height: (9em / 4);
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0px;
  width: 100%;
  @media (max-width: $breakpoint-font) {
    font-size: 6em;
  }
  @media (max-width: $breakpoint-font2) {
    padding-top: calc(#{$goldenRatio1} / 5);
    line-height: 1em;
  }
}

#slogan {
  //width: calc(24ch + 375px);
  text-align: center;
  display: inline-block;
  width: auto;
  white-space: nowrap;
}

.subheading {
  font-size: 3em;
  line-height: (3em / 2);
  height: 46%;
  text-align: center;
}

.textrotation-container {
  display: inline-block;
  @media (max-width: $breakpoint-font) {
    display: block;
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    line-height: 1em;
  }
}

.textrotation {
  font-weight: bold;
  display: inline-block;

  max-height: 1.1em;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: -0.03em;
  text-align: left;
}

.city-container {
  @include grid-column(12);
}

.city {
  @include grid-column(6);

  p,
  h3 {
    margin: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    font-weight: bold;
  }
  p {
    font-size: 0.65em;
  }

  h3 {
    display: inline;
    font-size: 0.9em;
    font-weight: normal;
  }
  font-size: 2.65em;
  text-align: center;
  font-family: ostrich_sansmedium, serif;

  @media (max-width: $breakpoint-font2) {
    @include grid-column(12);
    @include grid-push(0);
    h3 {
      font-size: 0.7em;
    }
    p {
      font-size: 0.55em;
    }
    svg.svg-inline--fa.fa-w-12 {
      width: 0.55em;
    }
  }
}
.socialButtons {
  @include grid-column(8);
  @include grid-push(2);
  @media (max-width: $breakpoint-font) {
    @include grid-column(12);
    @include grid-push(0);
  }
  text-align: center;
}

a {
  @include hacks();
}

.get_in_touch {
  margin: 0;
  font-size: 1.55em;
  text-align: center;
}

.socialButton {
  text-align: center;
  margin-bottom: 0px; //20px;
  margin-top: 20px;

  $padding: 50px;
  a {
    display: inline-block;
    border-radius: 100%;
    border: 1px solid black;
    width: calc(3em + #{$padding});
    height: calc(3em + #{$padding});
    i,
    svg {
      padding-top: calc($padding / 3);
    }
    &:hover {
      @include bounce-in();
    }
  }

  &.email a {
    color: blue;
  }

  &.github a {
    color: black;
  }

  &.xing a {
    color: #00d296;
  }

  &.dock51 a {
    color: #e13827;
  }

  &.keybase a {
    color: #ff6f21;
  }

  &.linkedin a {
    color: #0077b5;
  }
}

#cursor {
  position: relative;
  margin-left: -5px;
}

#privacy-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  @media (max-width: $breakpoint-font2) {
    top: 20px;
    right: 20px;
  }
  cursor: pointer;
}

#privacy-container {
  padding-left: 20px;
  padding-right: 20px;
  p,
  ul,
  li {
    font-family: sans-serif;
  }
}

#dock51svg {
  vertical-align: -8px;
  width: 64px;
  height: 64px;
  font-size: 64px;
  height: 64px;
  line-height: 73px;
}

button {
  font-family: ostrich_sansmedium, serif;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 3%;
  top: 3%;
}
