html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: ostrich_sansmedium;
  src: url("ostrichsans-medium-webfont.426b92c8.woff2") format("woff2"), url("ostrichsans-medium-webfont.58d9ef46.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, .container {
  height: 100%;
  width: 100%;
  font-family: ostrich_sansmedium, serif;
}

.container:after {
  clear: both;
  content: "";
  display: block;
}

.three {
  width: 25%;
  float: left;
  margin-left: 0;
}

@media (max-width: 540px) {
  .three {
    width: 33.3333%;
    float: left;
    margin-left: 0;
  }
}

.six {
  width: 50%;
  float: left;
  margin-left: 0;
}

@media (max-width: 540px) {
  .six {
    width: 33.3333%;
    float: left;
    margin-left: 0;
  }
}

.four {
  width: 33.3333%;
  float: left;
  margin-left: 0;
}

.twelve {
  width: 100%;
  float: left;
  margin-left: 0;
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.visually-hidden {
  opacity: 0;
}

.goldenRatio1 {
  height: 61.8034%;
}

@media (max-width: 850px) {
  .goldenRatio1 {
    height: auto;
  }
}

.goldenRatio2 {
  height: 38.1966%;
}

@media (max-width: 850px) {
  .goldenRatio2 {
    height: auto;
  }
}

.headingName {
  text-align: center;
  height: 61.8034%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  width: 100%;
  margin: 0;
  font-size: 9.5em;
  font-weight: bold;
  line-height: 2.25em;
}

@media (max-width: 850px) {
  .headingName {
    font-size: 6em;
  }
}

@media (max-width: 540px) {
  .headingName {
    padding-top: 12.3607%;
    line-height: 1em;
  }
}

#slogan {
  text-align: center;
  width: auto;
  white-space: nowrap;
  display: inline-block;
}

.subheading {
  height: 46%;
  text-align: center;
  font-size: 3em;
  line-height: 1.5em;
}

.textrotation-container {
  display: inline-block;
}

@media (max-width: 850px) {
  .textrotation-container {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    line-height: 1em;
    display: block;
  }
}

.textrotation {
  max-height: 1.1em;
  white-space: nowrap;
  text-align: left;
  margin-bottom: -.03em;
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.city-container {
  width: 100%;
  float: left;
  margin-left: 0;
}

.city {
  width: 50%;
  float: left;
  text-align: center;
  margin-left: 0;
  font-family: ostrich_sansmedium, serif;
  font-size: 2.65em;
}

.city p, .city h3 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0;
  font-weight: bold;
}

.city p {
  font-size: .65em;
}

.city h3 {
  font-size: .9em;
  font-weight: normal;
  display: inline;
}

@media (max-width: 540px) {
  .city {
    width: 100%;
    float: left;
    margin-left: 0;
  }

  .city h3 {
    font-size: .7em;
  }

  .city p {
    font-size: .55em;
  }

  .city svg.svg-inline--fa.fa-w-12 {
    width: .55em;
  }
}

.socialButtons {
  width: 66.6667%;
  float: left;
  text-align: center;
  margin-left: 16.6667%;
}

@media (max-width: 850px) {
  .socialButtons {
    width: 100%;
    float: left;
    margin-left: 0;
  }
}

a {
  vertical-align: middle;
  display: inline-block;
  transform: perspective(1px)translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.get_in_touch {
  text-align: center;
  margin: 0;
  font-size: 1.55em;
}

.socialButton {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
}

.socialButton a {
  width: calc(3em + 50px);
  height: calc(3em + 50px);
  border: 1px solid #000;
  border-radius: 100%;
  display: inline-block;
}

.socialButton a i, .socialButton a svg {
  padding-top: 16.6667px;
}

.socialButton a:hover {
  vertical-align: middle;
  transition-duration: .5s;
  display: inline-block;
  transform: perspective(1px)translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.socialButton a:hover:hover, .socialButton a:hover:focus, .socialButton a:hover:active {
  transition-timing-function: cubic-bezier(.47, 2.02, .31, -.36);
  transform: scale(1.2);
}

.socialButton.email a {
  color: #00f;
}

.socialButton.github a {
  color: #000;
}

.socialButton.xing a {
  color: #00d296;
}

.socialButton.dock51 a {
  color: #e13827;
}

.socialButton.keybase a {
  color: #ff6f21;
}

.socialButton.linkedin a {
  color: #0077b5;
}

#cursor {
  margin-left: -5px;
  position: relative;
}

#privacy-button {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media (max-width: 540px) {
  #privacy-button {
    top: 20px;
    right: 20px;
  }
}

#privacy-container {
  padding-left: 20px;
  padding-right: 20px;
}

#privacy-container p, #privacy-container ul, #privacy-container li {
  font-family: sans-serif;
}

#dock51svg {
  vertical-align: -8px;
  width: 64px;
  height: 64px;
  height: 64px;
  font-size: 64px;
  line-height: 73px;
}

button {
  color: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
  font-family: ostrich_sansmedium, serif;
  position: absolute;
  top: 3%;
  right: 3%;
}

/*# sourceMappingURL=index.ff5a3fb6.css.map */
